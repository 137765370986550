<template>
  <div>
    <h3>Aviso Legal</h3>
    <div>
      <h4>INFORMACIÓN LEGAL</h4>
      <p>
        De acuerdo con la Ley 34/2002 de 11 de julio de servicios de la sociedad
        de la información ponemos en su conocimiento que sociedad Inverpriban
        Rent S.L., (en adelante Inverpriban) con C.I.F. B98233760 y domicilio en
        Paseo de Gracia 37, 3 – 1, 08007 Barcelona. Para cualquier consulta o
        propuesta, contáctenos en el correo electrónico: info@inverpriban.com o
        llamando al teléfono 934 870 380.
      </p>
    </div>
    <div >
      <h4>USO DE DATOS PERSONALES</h4>
      <p>
        Inverpriban les comunica que pone en conocimiento de los usuarios del
        sitio web www.inverpriban.com su compromiso y cumplimiento con la
        legislación vigente sobre la gestión y protección de datos personales.
        Para su conocimiento detallado puede consultar más información, haciendo
        clic aquí.
      </p>
    </div>
    <div>
      <h4>CONDICIONES GENERALES DE USO DE LA WEB</h4>
      <p>
        El acceso a nuestra página web por parte del usuario es gratuito y está
        condicionado a la previa lectura y aceptación integra, expresa y sin
        reservas de las presentes condiciones generales de uso vigente en el
        momento del acceso. Si el usuario no estuviese de acuerdo con las
        presentes condiciones, deberá abstenerse de utilizar este portal y
        operar por medio del mismo.
      </p>
      <p >
        Inverpriban no se responsabiliza de las posibles discrepancias que, con
        carácter transitorio, puedan surgir entre la versión de sus documentos
        impresos y la versión electrónica de los mismos publicados en su página
        web.
      </p>
      <p>
        La información facilitada por Inverpriban debe ser considerada por el
        usuario a modo de introducción, sin que pueda estimarse como elemento
        determinante para la toma de decisiones.
      </p>
      <p >
        A través de nuestra página web ofrecemos información sobre Inverpriban y
        nuestros productos y servicios financieros, así como contenidos,
        informaciones y noticias relativas al sector que representamos, sin que
        ello suponga relación profesional, mercantil ni laboral alguna con el
        usuario.
      </p>
      <p >
        Esta página web se rige por la normativa exclusivamente aplicable en
        España quedando sometida a ella, tanto para usuarios nacionales como
        extranjeros que utilicen esta web.
      </p>
      <p >
        En cualquier momento podremos modificar la presentación y configuración
        de nuestra web e incluso suprimirla de la red, así como los servicios y
        contenidos prestados, todo ello de forma unilateral y sin previo aviso.
      </p>
    </div>
    <div >
      <h4>DERECHOS DE AUTOR</h4>
      <p>
        Esta página web es una obra de Inverpriban y le pertenece como autor a
        tenor de lo establecido en el Texto Refundido de la Ley de Propiedad
        Intelectual (1/1996). Todos los contenidos, fotografías, textos, diseños
        e imágenes presentes en nuestra página web son de nuestra propiedad o de
        terceros que han autorizado su uso, y están protegidos por los derechos
        de Propiedad Intelectual. El usuario únicamente tiene derecho a un uso
        privado de los mismos, y necesita autorización expresa de Inverpriban
        para modificarlos, reproducirlos, explotarlos, distribuirlos y
        especialmente comercializarlos, o hacer uso de cualquier derecho
        perteneciente a su titular.
      </p>
      <p >
        Inverpriban es una marca registrada, y está prohibida su reproducción,
        imitación, utilización o inserción de estas marcas sin nuestra debida
        autorización.
      </p>
    </div>
    <div >
      <h4>CONDICIONES DE ACCESO</h4>
      <p>
        El acceso a nuestra página web es gratuito y no requiere previa
        suscripción o registro. No obstante, para la utilización de determinados
        servicios, se puede requerir la previa suscripción y cumplimentación de
        formularios. Estos casos, estarán debidamente identificados, indicando
        en cada momento los procedimientos a seguir para llevar a cabo el
        correspondiente registro.
      </p>
      <p >
        El usuario debe acceder a nuestra página web con motivo de consulta,
        siguiendo las normas de orden público, a las presentes Condiciones
        Generales de Uso y, en su caso, las condiciones específicas de uso que
        pudieran ser de aplicación. El acceso a nuestro sitio web se realiza
        bajo la propia y exclusiva responsabilidad del usuario, que responderá
        en todo caso de los daños y perjuicios que pueda causar a terceros o a
        nosotros mismos.
      </p>
      <p >
        El usuario tiene expresamente prohibido la utilización y obtención de
        los servicios y contenidos ofrecidos en la presente página web, por
        procedimientos distintos a los estipulados en las estas condiciones de
        uso, y en su caso en las condiciones particulares que regulen la
        adquisición de determinados servicios.
      </p>
      <p >
        Teniendo en cuenta la imposibilidad de control respecto a las
        informaciones, contenidos, opiniones y servicios que contengan otras
        páginas webs a las que se pudiera acceder a través de los enlaces
        (links, banners, botones, buscadores) que nuestra página web pueda poner
        a su disposición, le comunicamos que Inverpriban queda eximida de
        cualquier responsabilidad por los daños y perjuicios de toda clase que
        pudiesen derivar por la utilización de esas páginas web ajenas a nuestra
        empresa por parte del usuario, al que recomendamos leer los avisos
        legales que regulen todos los sitios web.
      </p>
      <p >
        La existencia de hiperenlaces a nuestra página web desde sitios webs
        ajenos a nosotros, no implicará en ningún caso la existencia de
        relaciones comerciales o mercantiles con el titular de la página web
        donde se establezca el hiperenlace, ni la aceptación por parte del
        Inverpriban de sus contenidos o servicios.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h2 {
  margin-bottom: 1.5rem;
  margin-top: 2.5rem;
}
</style>
