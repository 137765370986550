<template>
  <section class="info-cards" >
    <div class="tw-container tw-px-5 tw-mx-auto lg:tw-px-0 stadistics-container">
      <h3 class="section-heading">Nuestras estadísticas</h3>
      <div class="tw-flex tw-flex-wrap ">
        <div class="tw-w-full tw-mt-5 md:tw-w-1/2 md:tw-mt-0">
          <section class="card-info">
            <img src="img/icons/managed-euros.png" class="icon" />
            <h1 class="heading ">€<span class="counter1 heading">0</span></h1>
            <p class="copy">Millones gestionados</p>
          </section>
        </div>
        <div class="tw-w-full tw-mt-16 md:tw-w-1/2 md:tw-mt-0">
          <section class="card-info">
            <img src="img/icons/hand-shake.svg" class="icon" />
            <h1 class="heading counter2">0</h1>
            <p class="copy">Inversores satisfechos</p>
          </section>
        </div>
        <div class="tw-w-full tw-mt-16 md:tw-w-1/2 md:tw-mt-24">
          <section class="card-info">
            <img src="img/icons/completed.svg" class="icon" />
            <h1 class="heading counter3">0</h1>
            <p class="copy">Operaciones realizadas</p>
          </section>
        </div>
        <div class="tw-w-full tw-mt-16 md:tw-w-1/2 md:tw-mt-24">
          <section class="card-info">
            <img src="img/icons/experience.svg" class="icon" />
            <h1 class="heading counter4">0</h1>
            <p class="copy">Años de experiencia</p>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      loaded: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    count1() {
      var counter = { var: 0 };
      TweenMax.to(counter, 3, {
        var: 112000000,
        onUpdate: () => {
          var number = Math.ceil(counter.var);
          $(".counter1").html(this.format(number));
        }
      });
    },
    count2() {
      var counter = { var: 0 };
      TweenMax.to(counter, 3, {
        var: 1200,
        onUpdate: function() {
          var number = Math.ceil(counter.var);
          $(".counter2").html(number);
        }
      });
    },
    count3() {
      var counter = { var: 0 };
      TweenMax.to(counter, 3, {
        var: 2540,
        onUpdate: () => {
          var number = Math.ceil(counter.var);
          $(".counter3").html(this.format(number));
        }
      });
    },
    count4() {
      var counter = { var: 0 };
      TweenMax.to(counter, 4, {
        var: 18,
        onUpdate: function() {
          var number = Math.ceil(counter.var);
          $(".counter4").html(number);
        }
      });
    },
    handleScroll(event) {
      if (!this.loaded) {
        this.isInViewport();
      }
    },
    isInViewport() {
      var element = window.document.getElementById("trigger");
      var rect = element.getBoundingClientRect();
      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.right <=
          (window.innerWidth || document.documentrect.clientWidth) &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.count1();
        this.count2();
        this.count3();
        this.count4();
        this.loaded = true;
      }
    },
    format(number) {
        return new Intl.NumberFormat("de-DE").format(number);
    }
  }
};
</script>
