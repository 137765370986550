<template>
  <div :class="{ 'wizard-form-container': wizard }">
    <h5 class="tw-mb-12" :class="{ 'tw-hidden': wizard }">
      <div v-if="!contact">Nos pondremos en contacto contigo en un plazo máximo de 24h</div>
        <div v-else>En breve, nos comunicaremos contigo.</div>
    </h5>
    <v-form ref="form" action="" v-model="valid" lazy-validation>
      <div class="tw-flex" :class="{ 'tw-flex-col lg:tw-flex-row w-full': wizard || contact }">
        <div class="form-group tw-pr-2" :class="[wizard || contact ? 'tw-w-full lg:tw-w-1/2' : ' tw-w-1/2']">
          <label for="">Nombre</label>
          <v-text-field
            v-model="form.name"
            :rules="[v => !!v || 'Debe introducir un nombre']"
            required
            outlined
          ></v-text-field>
        </div>
        <div class="form-group tw-pl-2" :class="[wizard || contact ? 'tw-w-full lg:tw-w-1/2' : ' tw-w-1/2']">
          <label for="">Apellidos</label>
          <v-text-field
            v-model="form.surname"
            :rules="[v => !!v || 'Debe introducir un apellido']"
            required
            outlined
            class="focus:outline-none"
          ></v-text-field>
        </div>
      </div>
      <div :class="{ 'tw-flex tw-flex-col lg:tw-flex-row tw-w-full': wizard , 'tw-flex tw-flex-col lg:tw-flex-row tw-w-full': contact  }">
        <div class="form-group" :class="{ 'tw-w-full lg:tw-w-1/2 tw-pr-2': wizard, 'tw-w-full lg:tw-w-1/2 tw-pr-2': contact }">
          <label for="">Email</label>
          <v-text-field
            v-model="form.email"
            :rules="emailRules"
            required
            outlined
          ></v-text-field>
        </div>
        <div class="form-group" :class="{ 'tw-w-full lg:tw-w-1/2 tw-pl-2': wizard, 'tw-w-full lg:tw-w-1/2 tw-pl-2': contact }">
          <label for="">Teléfono</label>
          <v-text-field
            v-model="form.phone"
            :rules="[v => !!v || 'Debe introducir un teléfono']"
            required
            outlined
          ></v-text-field>
        </div>
      </div>
      <div class="">
        <input type="checkbox" id="terms" class="tw-mr-3"
        v-model="form.terms"

        />
        <label for="terms"
          >Acepto la <a style="color:#b70219" target="_blank" href="/politica-de-privacidad">política de privacidad</a></label
        >
        <p v-if="!form.terms" style="color:red;font-size:12px">{{msgcheckbox}}</p>
      </div>
      <div class="tw-flex tw-mt-6 tw-w-full" :class="[contact ? 'tw-justify-end' : 'tw-justify-between']">
        <div class="tw-p-2 tw-w-1/2" :class="{ 'tw-hidden': wizard || contact}">
          <button
            class="btn tw-w-full focus:tw-outline-none tw-flex tw-items-center tw-btn-wizard-back"
            @click.prevent="backstep"
          >
            <img
              src="icons/arrow_right_alt-black-18dp.svg"
              class="icon-rotated"
            />Atrás
          </button>
        </div>
        <div class="tw-p-2 " :class="[wizard ? 'tw-w-full' : 'tw-w-1/2', {'tw-flex tw-justify-center lg:tw-justify-end':contact}]">
          <spinner-button-component
            :disabled="! valid || loading"
            :loading="loading"
            id="lead"
            class="btn btn-primary-black tw-w-full focus:tw-outline-none tw-relative"
            :class="{'bg-red':contact}"
            @click="submitform"
          >
            {{ contact ? 'Me interesa' : 'Enviar' }}
          </spinner-button-component>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    home: {
      type: Boolean,
      default: false
    },
    wizard: {
      type: Boolean,
      default: false
    },
    contact: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: true,
    msgcheckbox: '',
     emailRules: [
        v => !!v || 'Debe introducir un email',
        v => /.+@.+\..+/.test(v) || 'Debe introducir un email válido',
      ],
    form: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      terms: false
    },
    errors: [],
    loading: false
  }),
  methods: {
    backstep() {
      this.$emit("currentstep", 1);
    },
    submitform() {
      if (! this.$refs.form.validate()) {
        return;
      }
      if(!this.form.terms){
        this.msgcheckbox = 'Debe aceptar la política de privacidad'
        return;
      }
      this.loading = true;
      axios
        .post("/api/sendSimulation", {
            form: this.form,
            optionSelected: this.$store.state.optionSelected,
            investment: this.$store.state.investment
        })
        .then(() => {
            this.resetData();
        })
        .finally(() => {
            this.loading = false;
        });
    },
    resetData() {
        this.$refs.form.reset();
        this.form.terms = false;
        this.$store.commit("SET_OPTION_SELECTED", null);
        this.$emit('reset');
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-wizard-back {
  padding-left: 0 !important;
}
.icon-rotated {
  width: 30px;
  transform: rotate(180deg);
}
h5 {
  font-size: 20px;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
}
h4 {
  font-size: 22px;
  font-weight: bold;
}
.wizard-form-container {
  max-width: 500px;
  margin: auto;
  padding: 5rem 0;
}
.bg-red{
    background-color: #b70219!important;
    max-width: 170px!important;
}
label[for="terms"] {
  font-size: 1.4rem;
  font-weight: 600;
  a {
    font-size: 1.4rem;
    font-weight: 600;
  }
}
.btn {
  padding: 0 1.8rem;
}
</style>
