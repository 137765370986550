<template>
  <div class="tw-w-full" :class="[home ? 'home-wizard-component-container' : '']">
    <div v-if="wizard" class="wizard-title">
      <div v-if="currentstep == 1">
        <h2>Simula tu inversión</h2>
      </div>
      <div v-if="currentstep == 2">
          <div class="tw-w-full button-back-container tw-flex">
            <button
            class="btn focus:outline-none items-center btn-wizard-back"
            @click.prevent="backstep"
            >
            <div class="tw-flex tw-items-center">
            <img
                src="icons/arrow_right_alt-black-18dp.svg"
                class="icon-rotated"
            />Volver atrás
            </div>
            </button>
          </div>
        <h2>Nos pondremos en contacto contigo en un plazo máximo de 24h</h2>
      </div>
    </div>
    <div
      :class="[home ? 'home-wizard-component' : 'wizard-component']"
      data-app
    >
      <transition name="component-fade" mode="out-in">
        <main-component
          v-if="currentstep == 1"
          @currentstep="setcurrentstep"
          :home="home"
          :wizard="wizard"
          :contact="contact"
          ref="main"
        ></main-component>
        <form-component
          v-else-if="currentstep == 2"
          @currentstep="setcurrentstep"
          :home="home"
          :wizard="wizard"
          :contact="contact"
          @reset="reset"
        ></form-component>
        <!-- <router-view></router-view> -->
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    home: {
      type: Boolean,
      default: false
    },
    wizard: {
      type: Boolean,
      default: false
    },
    contact:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentstep: 1
    };
  },
  methods: {
    setcurrentstep(val) {
      this.currentstep = val;
    },
     backstep() {
      this.currentstep = 1;
    },
    reset() {
      this.currentstep = 1;
      setTimeout(() => {
        this.$refs.main.$refs.form.reset();
      }, 400);
    }
  }
};
</script>
<style lang="scss">
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.home-wizard-component {
  background-color: #ffffff;
  width: 420px;
  height: auto;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 3rem;
  @media (max-width: 1023px) {
    width: 100%;
    padding: 4rem;
  }
  @media (max-width: 599px) {
      padding: 4rem 2.6rem;
  }
  * {
    font-family: "Nunito Sans";
  }
}
.wizard-component {
  background-color: #ffffff;
  height: auto;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  max-width: 900px;
  width: 100%;
  margin: auto;
  @media (max-width: 1023px) {
    width: 100%;
    background-color: transparent !important;
    box-shadow: unset;
    padding: 1.5rem 0;
  }
  * {
    font-family: "Nunito Sans";
  }
}
.wizard-title {
  text-align: center;
  margin: 3rem 0;
  @media (max-width: 1023px) {
      padding-top: 30px;
        margin:0;

  }
  h2 {
    font-family: "Nunito Sans";
    font-size: 30px;
     @media (max-width: 1023px) {
     font-size: 25px;

  }
  }
}
.btn-wizard-back {
  padding-left: 0 !important;
}
.icon-rotated {
  width: 30px;
  transform: rotate(180deg);
}
.button-back-container{
    padding-left: 10%;
    margin: 5rem 0;
}
.home-wizard-component-container {
  @media (max-width: 599px) {
    width: 100vw;
    margin-left: -16px;
  }
}
</style>
