import AvisoLegal from '../components/legal/AvisoLegal';
import CondicionesGenerales from '../components/legal/CondicionesGenerales';
import PoliticaCookies from '../components/legal/PoliticaCookies';
import FaqsComponent from '../components/legal/FaqsComponent';
import ReclamacionesComponent from '../components/legal/ReclamacionesComponent';
import LegalComponent from '../components/LegalComponent';

const routes = {
    path: '/legalidad',
    component: LegalComponent,
    children: [
        { name: 'Aviso legal', path: 'aviso-legal', component: AvisoLegal },
        { name: 'Condiciones generales', path: 'condiciones-generales', component: CondicionesGenerales },
        { name: 'Política de Cookies', path: 'politica-de-cookies', component: PoliticaCookies },
        { name: 'Preguntas frecuentes', path: 'preguntas-frecuentes', component: FaqsComponent },
        { name: 'Reclamaciones', path: 'reclamaciones', component: ReclamacionesComponent },
    ]
};

export default routes;
