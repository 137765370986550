
window.Vue = require("vue");
import Vuetify, { VForm, VCheckbox, VTextField, VTextarea, VSelect, VMenu } from "vuetify/lib";
import { store } from "./store";
import VueRouter from "vue-router";
import { Ripple } from "vuetify/lib/directives";
import routes from "./routes";
Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
    routes
});
window.router = router;

Vue.component('home-wizard', require('./components/home-wizard/HomeWizard.vue').default);
Vue.component('form-component', require('./components/home-wizard/FormComponent.vue').default);
Vue.component('main-component', require('./components/home-wizard/MainComponent.vue').default);
Vue.component('contact-form-component', require('./components/ContactFormComponent.vue').default);
Vue.component('contact-us-simulator-component', require('./components/home-wizard/ContactUsSimulatorComponent.vue').default);
Vue.component('spinner-button-component', require('./components/ui/SpinnerButtonComponent.vue').default);
Vue.component('legal-component', require('./components/LegalComponent.vue').default);
Vue.component('counter-component', require('./components/counter/CounterComponent.vue').default);


Vue.use(Vuetify, {
  components: {
    VSelect,
    VTextField,
    VTextarea,
    VForm,
    VCheckbox,
    VMenu
  },
  directives: {
    Ripple
  },
});

window.vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0fc0cc",
        secondary: "#ffffff",
        accent: "#0fc0cc",
        error: "#fff",
        info: "#f44336",
        success: "#4CAF50",
        warning: "#FFC107"
      },
      dark: {
        primary: "#0fc0cc",
        secondary: "#000000",
        accent: "#0fc0cc",
        error: "#f44336",
        info: "#f44336",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});

const app = new Vue({
  el: "#app",
  store,
  vuetify,
  router
});
