import Vuex from "vuex";
import Vue from "vue";
import { GET_INVESTMENT,GET_OPTION_SELECTED } from "./getter-types";
import { SET_INVESTMENT,SET_OPTION_SELECTED } from "./mutation-types";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    investment: null,
    optionSelected: null,
  },
  getters: {
    [GET_INVESTMENT](state) {
      return state.investment;
    },
    [GET_OPTION_SELECTED](state) {
        return state.optionSelected;
      },
  },
  mutations: {
    [SET_INVESTMENT](state, payload) {
      state.investment = payload;
    },
    [SET_OPTION_SELECTED](state, payload) {
        state.optionSelected = payload;
    }
  },
  actions: {}
});

export { store };
