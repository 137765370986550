<template>
    <v-form v-model="valid" ref="form">
        <div class="tw-mx-auto lg:tw-px-0">
            <div class="tw-flex tw-flex-wrap md:tw-flex-no-wrap md:tw-space-x-5">
                <div class="tw-w-full">
                    <v-text-field
                        v-model="form.name"
                        :rules="[v => !!v || 'Debe introducir un nombre']"
                        placeholder="Nombre"
                        required
                        outlined
                        background-color="#fff"
                    ></v-text-field>
                </div>
                <div class="tw-w-full">
                    <v-text-field
                        v-model="form.phone"
                        :rules="[v => !!v || 'Debe introducir un teléfono']"
                        placeholder="Telefono"
                        required
                        outlined
                        background-color="#fff"
                        @keypress="isNumber($event)"
                    ></v-text-field>
                </div>
                <div class="tw-w-full">
                    <v-text-field
                        v-model="form.email"
                        :rules="emailRules"
                        placeholder="Email"
                        required
                        outlined
                        background-color="#fff"
                    ></v-text-field>
                </div>
            </div>
            <div class="tw-col-span-3">
                <v-textarea
                    v-model="form.message"
                    :rules="messageRules"
                    name="message"
                    placeholder="Mensaje"
                    outlined
                    background-color="#fff"
                ></v-textarea>
            </div>
            <div class="tw-flex tw-justify-center">
                <spinner-button-component id="contact" class="btn-primary" :loading="loading" @click="validate">
                    Enviar
                </spinner-button-component>
            </div>
        </div>
    </v-form>
</template>

<script>
export default {
    data() {
        return {
            valid: false,
            emailRules: [
                v => !!v || 'Debe introducir un email',
                v => /.+@.+\..+/.test(v) || 'Debe introducir un email válido',
            ],
            messageRules: [
                v => !!v || 'Debe introducir un mensaje',
                v => v.length >= 12 || 'El mensaje debe contener al menos 12 caracteres',
            ],
            form: {
                name: '',
                phone: '',
                email: '',
                message: ''
            },
            loading: false
        }
    },

    methods: {
        isNumber(event) {
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault();
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.submit();
            }
        },
        submit() {
            this.loading = true;

            axios.post('/api/contact', this.form)
                .then(() => {
                    this.$refs.form.reset();
                })
                .catch(() => {})
                .finally(() => this.loading = false);
        }
    },
}
</script>
