<template>
    <div class="wizard-container">
        <div class="w-element options">
            <main-component ref="main" contact></main-component>
        </div>
        <div class="w-element form">
            <form-component @reset="reset" contact></form-component>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        reset() {
            this.$refs.main.$refs.form.reset();
        }
    },
}
</script>
