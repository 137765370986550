<template>
    <div class="page-faqs">
        <h3 class="heading-3">Preguntas frecuentes</h3>

        <div class="tw-hidden lg:tw-block">
            <div v-for="faq in faqs" :key="faq.q">
                <hr class="tw-my-10">
                <div>
                    <h4 class="parragraph-heading">{{ faq.q }}</h4>
                    <div v-html="faq.a"></div>
                </div>
            </div>
        </div>

        <div class="tw-block lg:tw-hidden">
            <section class="collapsible-wrapper menu-item" v-for="faq in faqs" :key="faq.q">
                <button class="collapsible">
                    <span>{{ faq.q }}</span>
                    <span class="material-icons icon">add</span>
                </button>
                <div class="content" v-html="faq.a"></div>
                <hr class="tw-my-10">
            </section>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            faqs: [
                {
                    q: '¿Quiénes pueden invertir?',
                    a: `<p class="tw-mt-5">Personas físicas, con DNI o NIE, y empresas con cuenta bancaria en España.</p>`
                },
                {
                    q: '¿Qué tipo de documentos se solicitan para poder invertir?',
                    a: `<ul class="tw-mt-5">
                        <li>Si es persona física el DNI o NIE.</li>
                        <li>Si es persona jurídica con CIF o NIF, la escritura de poderes y Acta de Titularidad Real.</li>
                        <li>Y para ambos, identificar el número de cuenta bancaria de origen del dinero a invertir, junto con la declaración de la actividad económica y el origen de los fondos para cumplimiento normativo (Blanqueo de Capitales) y el número de cuenta donde se quiera recibir los intereses cada mes.</li>
                    </ul>`
                },
                {
                    q: '¿Cuál es el periodo de suscripción y compra?',
                    a: `<p class="tw-mt-5">Es según la disponibilidad de carteras.</p>`
                },
                {
                    q: '¿Cuánto es el importe mínimo para invertir?',
                    a: `<p class="tw-mt-5">A partir de 25.000 € sin límite máximo.</p>`
                },
                {
                    q: '¿Cuándo se hace el cobro de los intereses?',
                    a: `<p class="tw-mt-5">Los intereses serán liquidados mensualmente.</p>`
                },
                {
                    q: '¿Se puede retirar el dinero invertido en cualquier momento?',
                    a: `<p class="tw-mt-5">No, porque cada producto tiene un plazo específico de reembolso.</p>`
                },
                {
                    q: '¿Cuál es el tipo de garantía de la inversión?',
                    a: `<p class="tw-mt-5">La garantía se clasifica en activos inmobiliarios (viviendas, locales, edificios o licencias de taxi).</p>`
                },
                {
                    q: '¿En qué zonas del territorio español se encuentran las garantías?',
                    a: `<p class="tw-mt-5">Los mercados seleccionados son las capitales de provincia y poblaciones costeras de más de 20.000 habitantes. Especialmente en Madrid, Barcelona, Valencia, Bilbao y Baleares.</p>`
                }
            ]
        }
    },
}
</script>
