<template>
  <div class="legal-component">
    <div class="select tw-relative" @click="toggleSelect">
      <span></span>
      <span>{{ $route.name }}</span>
      <i
        class="material-icons mdc-button__icon"
        :class="{ open: selectIsOpen }"
        aria-hidden="true"
        >keyboard_arrow_down</i
      >
      <div
        v-show="selectIsOpen"
        class="options bg-white tw-absolute text-left font-light"
      >
        <div
          v-for="(route, index) in routes"
          :key="index"
          class="option tw-pl-5"
          :class="{ selected: isActive(route.path) }"
          @click="activeRoute = route.path"
        >
          {{ route.name }}
        </div>
      </div>
    </div>

    <div class="display-flex">
      <div class="menu-container">
        <ul class="fixed-menu mt-3">
          <li
            v-for="(route, index) in routes"
            :key="index"
            class="menu-item"
            :class="{ active: isActive(route.path) }"
            @click="activeRoute = route.path"
          >
            {{ route.name }}
          </li>
        </ul>
      </div>
      <transition name="fade" appear mode="out-in">
        <router-view class="router-content"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import routes from "../routes/legal";

export default {
  data() {
    return {
      activeRoute: null,
      selectIsOpen: false,
      routes: routes.children
    };
  },

  methods: {
    isActive(route) {
      return this.activeRoute === route;
    },
    toggleSelect() {
      this.selectIsOpen = !this.selectIsOpen;
    }
  },

  watch: {
    activeRoute(route, old) {
      const path = `/legalidad/${route}`;

      if (this.$route.path !== path) {
        this.$router.push({ path });
      }
    },
    selectIsOpen(open) {
      const hideSelect = e => {
        if (!$(e.target).closest(".select").length) {
          this.selectIsOpen = false;
        }
      };
      if (open) {
        document.body.addEventListener("click", hideSelect);
      } else {
        document.body.removeEventListener("click", hideSelect);
      }
    }
  },

  created() {
    const parts = window.location.pathname.split("/");
    this.activeRoute = parts.pop() || parts.pop();
    if (this.activeRoute === "legalidad") {
      this.activeRoute = "aviso-legal";
    }
  },
  beforeRouteEnter (to, from, next) {
    document.title = to.name;
    next();
  },
  beforeRouteUpdate (to, from, next) {
    document.title = to.name;
    next();
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
ul {
  list-style-type: none;
}
.select {
  @media (min-width: 1024px) {
    display: none;
  }
}
.display-flex {
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  .menu-container {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      flex-direction: column;
      position: -webkit-sticky; /* Safari */
      position: sticky;
      align-self: flex-start;
    }
  }
}
.router-content{
    flex-grow: 1;
    margin-top: 5rem;
    @media (min-width: 1024px) {
    margin-left: 5rem;
    margin-top: 0rem;
  }
}
</style>
