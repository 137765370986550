<template>
    <button :id="id" class="btn tw-relative" @click.prevent="$emit('click')">
        <span v-if="! loading">
            <slot></slot>
        </span>
        <img class="spinner tw-absolute" v-else src="/img/loading.gif" width="35">
    </button>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        id: {
            default: ''
        }
    },
}
</script>

<style scoped>
.spinner {
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
