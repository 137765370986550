<template>
  <div :class="{ 'tw-flex tw-flex-col lg:tw-flex-row': wizard }">
    <div :class="{ 'main-element': wizard }">
      <h5 class="tw-mb-12" :class="{ 'tw-hidden': wizard }">
        Simula tu inversión
      </h5>
      <v-form
        class="focus:tw-outline-none tw-my-6"
        :class="{ 'lg:tw-pr-8': wizard }"
        ref="form"
      >
        <div class="form-group">
          <label for="" class="tw-mb-3">¿Qué producto te interesa?</label>
          <div class="tw-relative">
            <button
              @click.prevent.stop="openMenu = !openMenu"
              class="btn-choice-options"
              :class="{ invalid: optionInvalid }"
            >
              <div v-if="optionSelected">
                <div class="tw-flex tw-justify-between">
                  <p class="tw-mr-6">{{ optionSelected.title }}</p>
                  <p>{{ optionSelected.value }} %</p>
                </div>
              </div>
              <div v-else>Escoge tu producto</div>
              <img src="/icons/expand_more-black-18dp.svg" alt="" />
            </button>
            <span class="small-error" v-if="error.optionerror"
              >Debes seleccionar una opción</span
            >
            <transition name="fade">
              <div class="menu-choice" v-show="openMenu">
                <div
                  v-for="(option, index) in options"
                  :key="index"
                  class="list-element"
                  @click.prevent="selectOption(option)"
                  :class="{
                    first: index == 0,
                    last: index == options.length - 1
                  }"
                >
                  <p class="title">{{ option.title }}</p>
                  <p class="percent">{{ option.value.toFixed(2) }} %</p>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="form-group tw-mt-6">
          <label for="" class="tw-mb-3">¿Cuánto dinero quieres invertir?</label>
          <div class="tw-flex tw-w-1/2">
            <v-text-field
              v-model.number="investment"
              outlined
              type="number"
              hide-details
              min="5"
              max="7"
              @keyup="minmax()"
              :rules="[v => !!v || 'Debe introducir una cantidad']"
            ></v-text-field>
            <p class="icon-dolar">€</p>
          </div>
          <span class="small-error" v-if="error.investerror"
            >Debes seleccionar una cantidad</span
          >
          <span class="small-error" v-if="error.investerrorminmax"
            >La inversión debe ser entre 25000 y 1000000</span
          >
        </div>
      </v-form>
      <hr class="tw-mb-6" :class="{ 'tw-hidden': wizard }" />
    </div>
    <div :class="{ 'main-element': wizard, 'bg-wizard-grey': wizard }">
      <div class="tw-mx-auto container-element">
        <p class="captionblack">Intereses</p>
        <div class="tw-flex tw-my-6">
          <div class="tw-flex-col tw-mr-6">
            <p class="captionblack">Mensualmente*:</p>
            <h4>{{ new Intl.NumberFormat("de-DE").format(monthlyInvestment()) }}€</h4>
          </div>
          <div class="tw-flex-col">
            <p class="captionblack">Anualmente:</p>
            <h4>{{ new Intl.NumberFormat("de-DE").format(anualInvestment()) }}€</h4>
          </div>
        </div>
        <p class="footnote">*Los intereses se cobran mensualmente</p>
        <div
          class="tw-flex tw-mt-6 tw-justify-between tw-w-full"
          :class="{ 'tw-hidden': wizard || contact }"
        >
          <div class="tw-p-2 tw-w-1/2">
            <button
              class="btn btn-outlined tw-w-full focus:outline-none"
              @click="goToProduct"
            >
              Más información
            </button>
          </div>
          <div class="tw-p-2 tw-w-1/2">
            <button
              class="btn btn-primary-black tw-w-full focus:tw-outline-none"
              @click="nextstep"
            >
              Me interesa
            </button>
          </div>
        </div>
        <div :class="{ 'tw-hidden': home || contact }">
          <div class="tw-p-2 tw-mt-8 tw-w-full">
            <button
              class="btn btn-primary-black tw-w-full focus:tw-outline-none"
              @click="nextstep"
            >
              Contáctanos
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-wizard"></div>
  </div>
</template>
<script>
export default {
  props: {
    home: {
      type: Boolean,
      default: false
    },
    wizard: {
      type: Boolean,
      default: false
    },
    contact: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    options: [
      {
        id: 1,
        title: "Renta Hipotecaria",
        value: 6.90
      },
      {
        id: 2,
        title: "Renta Inmobiliaria",
        value: 8.75
      }
    ],
    optionInvalid: false,
    openMenu: false,
    dialog: false,
    error:{
        optionerror: false,
        investerror:false,
        investerrorminmax:false
    }
  }),
  computed: {
    investment: {
      get() {
        return this.$store.state.investment;
      },
      set(investment) {
        this.$store.commit("SET_INVESTMENT", investment);
      }
    },
    optionSelected: {
      get() {
        return this.$store.state.optionSelected;
      },
      set(optionSelected) {
        this.$store.commit("SET_OPTION_SELECTED", optionSelected);
      }
    }
  },
  methods: {
      minmax(){
          if(this.investment < 25000 || this.investment > 1000000){
            this.error.investerrorminmax = true;
            return false;
          }else{
              this.error.investerrorminmax = false;
              return true;
          }
      },
    goToProduct() {
      if(!this.optionSelected){
        this.error.optionerror = true;
        return;
      }
      if (this.optionSelected != null) {
        switch (this.optionSelected.title) {
          case "Renta Hipotecaria":
            // this.$router.push('/inversion/renta-hipotecaria')
            window.location.href = "/inversion/renta-hipotecaria";
            break;
          case "Renta Inmobiliaria":
            // this.$router.push('/inversion/inmobiliaria')
            window.location.href = "/inversion/inmobiliaria";
            break;
        }
      }
    },
    validateForm(){
         this.error.optionerror = false;
         this.error.investerror = false;
         this.error.investerrorminmax = false;
        if(this.optionSelected && this.investment && this.minmax()){
            return true;
        }else{
            if(!this.optionSelected){
                this.error.optionerror = true;
            }
            if(!this.investment){
                this.error.investerror = true;
            }

            return false
        }
    },
    selectOption(val) {
      this.openMenu = false;
      this.optionSelected = val;
    },
    nextstep() {
    //   if (!this.optionSelected) {
    //     this.optionInvalid = true;
    //     return;
    //   }
      if (this.validateForm()) {
        this.$emit("currentstep", 2);
      }
    },
    monthlyInvestment() {
      const result = this.totalInvestment() / 12;
      return result.toFixed(0);
    },
    anualInvestment() {
      return this.totalInvestment().toFixed(0);
    },
    totalInvestment() {
      if (this.optionSelected && this.investment) {
        const result = (this.investment * this.optionSelected.value) / 100;
        return result;
      } else {
        return 0;
      }
    }
  },
  watch: {
    openMenu(openMenu) {
        if (openMenu) {
            this.optionInvalid = false;
            document.body.addEventListener('click', () => {
                this.openMenu = false;
            }, { once: true });
        }
    },
    optionSelected(val){
        this.error.optionerror = false;
    },
    investment(val){
        this.error.investerror = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.icon-dolar {
  align-self: center;
  font-size: 20px;
  font-weight: 600;
  margin-left: 1rem;
  margin-top: 5px;
}
h5 {
  font-size: 20px;
  font-weight: bold;
}
h4 {
  font-size: 22px;
  font-weight: bold;
}
p,
h4,
h5 {
  font-family: "Nunito Sans", sans-serif;
}
.footnote {
  font-size: 1.4rem;
}
.btn-choice-options {
  border-radius: 10px !important;
  border: solid 0.5px #c0bfd1 !important;
  display: flex;
  justify-content: space-between;
  height: 50px;
  font-size: 16px;
  padding: 12px;
  width: 100%;
  font-family: "Nunito Sans", sans-serif;

  &:focus {
    border-color: #0046ec !important;
    outline: none;
    border-radius: 10px !important;
  }
  img {
    width: 25px;
    height: 25px!important;
  }
  &.invalid {
    border: 1px solid red !important;
  }
}
.menu-choice {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  z-index: 10;

  .list-element {
    display: flex;
    padding: 2rem;
    justify-content: space-between;
    border: solid 1px #0046ec;
    &:hover {
      background-color: #f5f5fa;
      cursor: pointer;
    }
    &.first {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: none;
    }
    &.last {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .title {
      font-size: 18px;
    }
    .percent {
      text-align: center;
      align-self: center;
      font-size: 22px;
      font-family: "Nunito Sans", sans-serif;
      margin-top: 1px;
    }
  }
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.main-element {
  width: 50%;
  padding: 8rem 5rem;
  @media (max-width: 1023px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0rem;
    max-width: 500px;
  }
  &:first-child {
    background: white;
    @media (max-width: 1023px) {
      padding: 1.5rem;
    }
  }
  .container-element {
    margin: 0 auto;
  }
  &.bg-wizard-grey {
    background-color: #ebebf0;
    padding-left: 8rem;
    @media (max-width: 1023px) {
      padding-left: 0rem;
      padding: 1.5rem;
    }
    p {
      width: fit-content;
    }
    div button {
      width: 90% !important;
      @media (max-width: 1023px) {
        width: 100% !important;
      }
    }
  }
}
.btn {
  padding: 0 1.2rem;
}
.small-error {
  font-size: 12px;
  color: red;
}
</style>
